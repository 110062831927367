import React from 'react';
import { MastercardUploadWrapper } from './MastercardUpload.styled';
import CsvUpload from "../CsvUpload/CsvUpload";

interface Props {
    onUpload: () => void
}

const MastercardUpload = ({onUpload}: Props) => {
    const uploadCsvMastercard = async (image: any) => {
        const body = new FormData();
        body.append('file', image);
        await fetch('/api/upload/betalingenmastercard', {
            method: 'POST',
            body
        })
        onUpload();
    };

    return (

        <MastercardUploadWrapper>
            <CsvUpload title={"Mastercard"} onUpload={(image) => uploadCsvMastercard(image)}></CsvUpload>
        </MastercardUploadWrapper>
    );
};

export default MastercardUpload;
