import {useState, useEffect} from 'react';
import React from 'react'
import './App.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css'
import ApiClient from "./services/ApiClient";
import DebetUpload from "./components/DebetUpload/DebetUpload";
import MastercardUpload from "./components/MastercardUpload/MastercardUpload";
import BetalingenTable from "./components/BetalingenTable/BetalingenTable";
import BetalingenFilters from "./components/BetalingenFilters/BetalingenFilters";
import {Betaling} from "./models/Betaling";
import {Filters} from "./models/Filters";


function App() {
    const [betalingen, setBetalingen] = useState<Array<Betaling>>([]);
    const [filters, setFilters] = useState<Filters>({startDate: new Date(), endDate: new Date(), hideWithBijlage: false});
    const [loaded, setLoaded] = useState(false);

    function getBetalingen() {
        return ApiClient.getBetalingen(filters.startDate, filters.endDate, filters.hideWithBijlage)
            .then(
                (result: Betaling[]) => {
                    setBetalingen(result);
                    setLoaded(true);
                },
                (error: any) => {
                    setBetalingen([]);
                    setLoaded(true);
                }
            );
    }

    useEffect(() => {
        getBetalingen();
    }, [filters]);

    if (loaded === false) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="App">
                <div className="container-xxl container-xxxl">
                    <nav className={"navbar navbar-expand-lg fixed-top bg-light navbar-light"}>
                        <div className="container-xxl container-xxxl">
                            <h2>Onkosten app</h2>
                            <a className="navbar-brand" href="#"
                            ><img
                                id="logo"
                                src="./logo.svg"
                                alt="MaesD-IT Logo"
                                draggable="false"
                                height="50"
                                style={{"borderRadius": "5px"}}
                            /></a>
                        </div>
                    </nav>
                    <div style={{textAlign: "left"}} className={"mt-7"}>
                        <div>
                            <div className="row">
                                <div className="col">
                                    <DebetUpload
                                        onUpload={getBetalingen}></DebetUpload>
                                </div>
                                <div className="col">
                                    <MastercardUpload
                                        onUpload={getBetalingen}></MastercardUpload>
                                </div>
                            </div>
                        </div>
                        <div className={"mb-5"}>
                            <BetalingenFilters filters={filters} filtersUpdated={(filters: Filters) => setFilters(filters)}></BetalingenFilters>
                        </div>
                    </div>

                    <BetalingenTable betalingen={betalingen}
                                     onBijlageUploaded={getBetalingen}
                                     onHideBetaling={getBetalingen}
                    ></BetalingenTable>
                </div>
            </div>
        );
    }
}

export default App;
