import React, {ChangeEvent} from 'react';
import DatePicker from 'react-datepicker';
import {Filters} from "../../models/Filters";

interface Props {
    filters: Filters,
    filtersUpdated: (filters: Filters) => void
}

const BetalingenFilters = ({filters, filtersUpdated}: Props) => {

    function updateFilters(filters: Filters) {
        filtersUpdated(filters);
    }

    return (
        <div>
            <div className={"d-flex align-items-center mb-3"}>
                <div>
                    From: <DatePicker selected={filters.startDate} onChange={(startDate: Date) => updateFilters({hideWithBijlage: filters.hideWithBijlage, startDate: startDate, endDate: filters.endDate})}/>

                </div>
                <div>
                    To: <DatePicker selected={filters.endDate} onChange={(endDate: Date) => updateFilters({hideWithBijlage: filters.hideWithBijlage, startDate: filters.startDate, endDate: endDate})}/>
                </div>
            </div>
            <label>
                <input type="checkbox" checked={filters.hideWithBijlage} name="hideWithBijlage"
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateFilters({hideWithBijlage: (event.target as HTMLInputElement).checked, startDate: filters.startDate, endDate: filters.endDate})}
                />
                Hide onkosten with bijlage
            </label>
        </div>
    );
};

export default BetalingenFilters;
