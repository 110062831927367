import {Betaling} from "../models/Betaling";

const ApiClient = {
    getBetalingen(startDate: Date, endDate: Date, hideWithBijlage: boolean): Promise<Array<Betaling>> {
        return fetch("/api/betalingen" + '?startDate=' + startDate.toISOString().split('T')[0] + '&endDate=' + endDate.toISOString().split('T')[0] + '&hideWithBijlage=' + hideWithBijlage)
            .then(res => res.json())
            .then(
                (result) => {
                    return result;
                },
                (error) => {
                    return [];
                }
            )
    }
};

export default ApiClient;