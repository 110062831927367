import React from 'react';
import {BetalingenTableWrapper} from './BetalingenTable.styled';
import {FileUploader} from "react-drag-drop-files";
import {Betaling} from "../../models/Betaling";

interface Props {
    betalingen: Betaling[],
    onBijlageUploaded: () => {},
    onHideBetaling: () => {}
}

const BetalingenTable = ({betalingen, onBijlageUploaded, onHideBetaling}: Props) => {

    const fileTypes = ['JPG', 'JPEG', 'PNG', 'GIF', 'PDF'];
    const hideBetaling = async (id: string) => {
        await fetch('/api/betalingen/' + id + "/hide", {method: 'POST'});
        onHideBetaling();
    };

    function DragDrop(id: string) {
        const uploadBijlage = async (file: any, id: string) => {
            const body = new FormData();
            body.append('file', file);
            await fetch('/api/betalingen/' + id + "/bijlage", {
                method: 'POST',
                body
            });
            onBijlageUploaded();
        };

        return (
            <FileUploader handleChange={(file: any) => {
                uploadBijlage(file, id)
            }} name="file" types={fileTypes} classes={"drop_area"}/>
        );
    }

    const renderBijlageButton = (betaling: Betaling) => {
        if (betaling.bijlage) {
            return (<p>Bijlage is geupload</p>);
        } else {
            return (
                <div>
                    {DragDrop(betaling.id)}
                </div>
            )
        }
    }

    return (
        <BetalingenTableWrapper>
            <table className="table align-items-center table-flush">
                <thead className="thead-light">
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Bedrag</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Begunstigde</th>
                    <th scope="col">Upload</th>
                    <th scope="col">hide</th>
                </tr>
                </thead>
                <tbody>
                {betalingen.map((b, i) => (
                        <tr key={i}>
                            <td scope="row">{b.id}</td>
                            <td style={{whiteSpace: 'nowrap'}}>{b.bedrag}</td>
                            <td style={{whiteSpace: 'nowrap'}}>{b.datum}</td>
                            <td style={{whiteSpace: 'nowrap'}} className={"truncate-text"}>{b.begunstigde}</td>
                            <td style={{whiteSpace: 'nowrap', "maxWidth": "300px"}}>
                                {renderBijlageButton(b)}
                            </td>
                            <td>
                                <button className="btn btn-primary" name="hideBetaling"
                                        onClick={() => hideBetaling(b.id)}>Hide
                                </button>
                            </td>
                        </tr>
                ))}
                </tbody>
            </table>
        </BetalingenTableWrapper>
    );
};

export default BetalingenTable;
