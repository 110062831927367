import React, {useState} from 'react';
import {CsvUploadWrapper} from "./CsvUpload.styled";

interface Props {
    title: string,
    onUpload: (file: any) => void
}
const CsvUpload = ({title, onUpload}: Props) => {
    const [file, setFile] = useState(null);


    const uploadToClient = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            const i = event.target.files[0];

            setFile(i);
        }
    };

    return (

        <CsvUploadWrapper>
            <div className={"bg-light rounded shadow p-3 mb-5"}>

                <h2>{title}</h2>
                <div className={"d-flex align-items-center justify-content-between"}>

                    <input type="file" name="myImage" onChange={uploadToClient}/>
                    <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={() => onUpload(file)}
                    >
                        Send to server
                    </button>
                </div>
            </div>
        </CsvUploadWrapper>
    );
};

export default CsvUpload;
