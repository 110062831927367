import React from 'react';
import {DebetUploadWrapper} from './DebetUpload.styled';
import CsvUpload from "../CsvUpload/CsvUpload";

interface Props {
    onUpload: () => void
}

const DebetUpload = ({onUpload}: Props) => {
    const uploadCsv = async (image: any) => {
        const body = new FormData();
        body.append('file', image);
        await fetch('/api/upload/betalingen', {
            method: 'POST',
            body
        })
        onUpload();
    };

    return (

        <DebetUploadWrapper>
            <CsvUpload title={"Debet"} onUpload={(image) => uploadCsv(image)}></CsvUpload>
        </DebetUploadWrapper>
    );
};

export default DebetUpload;
