import styled from 'styled-components';

export const BetalingenTableWrapper = styled.div`
    .truncate-text {
        max-width: 600px; /* Adjust as needed */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .drop_area {
        border: 2px solid #8898aa;
    }

    .drop_area svg {
        display: none;
    }
`;